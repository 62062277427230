.titleCont {
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 55px;
  z-index: 1;
}

.img {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.imgCont {
  border-radius: 10px;
}

.rowBorder {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 30px;
}

.rowBorder:last-of-type {
  border-bottom: none;
  padding-bottom: 0px;
}

@media (min-width: 992px) {
  .titleCont {
    top: 0;
  }

  .rowBorder {
    border-bottom: none;
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
