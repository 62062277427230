.autocomplete {
  position: relative;

  input {
    background-color: var(--secondary);
    font-size: 1.2rem;
    color: white;
    border: 0;
  }

  input:focus {
    color: white;
    box-shadow: none;
    background-color: var(--gray-dark);
  }
}

.suggestions {
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--secondary);
  z-index: 99;
  .suggestion {
    &.highlighted {
      background: var(--primary);
    }
    cursor: pointer;
    padding: 5px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .autocomplete {
    input {
      font-size: 1.5rem;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
