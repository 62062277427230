.chapter {
  min-height: 100%;
  h2 {
    font-size: 1.125rem;
    font-weight: bold !important;
    font-family: var(--font-family-sans-serif);

    * {
      font-weight: bold !important;
    }
  }
  p {
    margin-bottom: 8px;
  }

  blockquote {
    margin-left: 50px;
    font-size: 1.4rem;
    font-family: 'Begum Sans', serif;
  }
}

.title {
  position: sticky;
  top: 0;
  background: linear-gradient(
    rgba(var(--gray-rgb), 1) 0%,
    rgba(var(--gray-rgb), 0.7) 85%,
    rgba(var(--gray-rgb), 0) 100%
  );
  z-index: 1;
}

.mobileTitle {
  position: sticky;
  top: 55px;
  background: linear-gradient(
    rgba(var(--gray-rgb), 1) 0%,
    rgba(var(--gray-rgb), 0.7) 85%,
    rgba(var(--gray-rgb), 0) 100%
  );
  z-index: 1;
}

.chevron {
  transition: transform var(--transition);
}
