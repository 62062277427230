.InfoBoxContainer {
  border-top: 1px solid var(--border-color);
}

.label::before {
  content: '─ ';
}

.rowBorder {
  border-top: 1px solid var(--border-color);
}

.rowDesc {
  border-top: none;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .rowDesc {
    border-top: 1px solid var(--border-color);
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
