.DocModalContent {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: var(--modal-z-index);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--secondary);

  .close {
    z-index: var(--modal-close-z-index);
    position: fixed;
    left: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
    color: white;
  }
}
