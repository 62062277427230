@keyframes makeVisible {
  to {
    visibility: visible;
  }
}
.DelayedSpinner {
  animation: makeVisible 0s linear 0.2s forwards;
  visibility: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
