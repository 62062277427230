.aboutContainer {
  height: 100%;
  overflow-y: auto;
}
.pageContent {
  padding-top: 15px;

  h6 {
    color: var(--primary);
  }
  h6::before {
    content: '– ';
  }
  p {
    font-size: 1rem;
  }
  a {
    color: white;
    border-bottom: 1px solid var(--primary);
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
}

.titleContainer {
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  background-color: var(--secondary);
  z-index: 1;
}

.logoLink {
  text-decoration: none;
  border-bottom: none !important;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  filter: grayscale(1);
  transition: filter var(--transition);
}

.logoLink:hover {
  filter: grayscale(0);
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .pageContent {
    p {
      font-size: 1.3rem;
    }
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
