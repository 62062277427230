.stack {
  position: relative;
  isolation: isolate;
  width: calc(100% - 30px);
  margin-bottom: 15px;
  h3 {
    z-index: 1;
  }
}

.stack:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.stackImage {
  position: absolute;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
  transform-origin: center;
  transition: transform var(--transition);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .stack {
    flex: 0 0 35%;
    max-height: 500px;
    max-width: 500px;
    margin-right: 50px;
    width: unset;
    padding: 0px;
    margin-bottom: 0px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .stack {
    flex: 0 0 25%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
