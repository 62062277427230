// Override variables
@import 'fonts';
@import 'variables';

// Boostrap scss
@import '~bootstrap/scss/bootstrap';

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --transition: 0.3s ease-in-out;
  --border-color: rgba(255, 255, 255, 0.5);
  --modal-z-index: 1021;
  --modal-close-z-index: 1022;
  --secondary-rgb: #{hexToRGB(map-get($theme-colors, 'secondary'))};
  --gray-rgb: #{hexToRGB(map-get($theme-colors, 'gray'))};

  @each $bp, $value in $container-max-widths {
    --max-width-#{$bp}: #{$value};
  }
}

body,
html,
#root {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body.no-overflow {
  overflow: hidden;
}

h1,
h2,
h3 {
  font-family: 'Begum Sans', serif;
}

.btn-icon-round {
  padding: 0.5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 100% !important;
}

.opacity-75 {
  opacity: 0.75;
}

.line-before::before {
  content: '– ';
}

.customCursor {
  cursor: url('./plus.svg') 48 48, auto;
}

input[type='range'] {
  // width: 100%;
  margin: 7px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  background: #ffffff;
  border: 0;
  width: 100%;
  height: 2px;
  cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
  margin-top: -7px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ffffff;
}
input[type='range']::-moz-range-track {
  background: #ffffff;
  border: 0;
  width: 100%;
  height: 2px;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  border-radius: 50px;
  cursor: pointer;
}
input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 7px 0;
  color: transparent;
  width: 100%;
  height: 2px;
  cursor: pointer;
}
input[type='range']::-ms-fill-lower {
  background: #f2f2f2;
  border: 0;
}
input[type='range']::-ms-fill-upper {
  background: #ffffff;
  border: 0;
}
input[type='range']::-ms-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
  background: #ffffff;
}
input[type='range']:focus::-ms-fill-upper {
  background: #ffffff;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

// TODO: just on mobile
.mapboxgl-ctrl-bottom-left {
  display: none !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .btn-icon-round {
    padding: 0.75rem !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
