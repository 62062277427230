.SeekContent {
  flex-grow: 0;
  display: flex;
  align-items: flex-end;
  margin: 0 10px;
  opacity: 0.8;
  transition: opacity var(--transition), color var(--transition);
}
.SeekContentSmall {
  opacity: 0.5;
  flex-basis: (100% / 10);
}
.SeekContentBig {
  flex-basis: (100% / 10) * 2;
}
.SeekContentNS {
  opacity: 0.8;
}

.SeekContent.active .SeekBackground,
.SeekContent:hover .SeekBackground {
  transform: scaleY(1.8);
}

.SeekContent.active,
.SeekContent:hover {
  opacity: 1 !important;
}

.SeekLineContainer {
  width: 100%;
}

.SeekBackground {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 5px;
  background-color: white;
  transform: scaleY(1);
  transition: transform var(--transition);
}

.SeekProgress {
  height: 100%;
  background-color: var(--primary);
}

.SeekPreview {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  opacity: 0.35;
  background-color: var(--primary);
  pointer-events: none;
  // transition: width 0.2s linear;
}

.SeekContent.active .SeekLineText:first-of-type,
.SeekContent:hover .SeekLineText:first-of-type {
  color: var(--primary);
}

.SeekLineText {
  font-size: 0.875rem;
  margin: 6px 0;
  line-height: 1.2;
  transition: color var(--transition);
}

.SeekLineText:first-of-type {
  cursor: pointer;
}
