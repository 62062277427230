.grid {
  --gap: 16px;
  display: grid;
  width: 100%;
  height: 100%;
  grid-auto-rows: calc(100% / 9 - var(--gap));
  grid-auto-columns: calc(100% / 18 - var(--gap));
  justify-content: center;
  align-content: center;
  grid-gap: var(--gap);
}

.gridItem {
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  color: white;
  text-decoration: none;
  overflow-y: auto;
  transform: scale(1);
  transition: transform var(--transition);
  z-index: 0;
}

.shadow {
  position: sticky;
  background: linear-gradient(0deg, var(--secondary) 0%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  width: 100%;
  height: 20px;
  z-index: 1;
}

.gridItem p {
  font-size: 1.5rem;
  margin-bottom: 0;
  max-width: 770px;
}

.gridItem.image:hover {
  text-decoration: none;
  color: white;
  transform: scale(1.05);
  z-index: 1;
}

.gridItem.image {
  border-radius: 10px;
  overflow-y: hidden !important;
}

.gridItem.image h3 {
  position: relative;
  font-size: 1.5rem;
  z-index: 2;
  hyphens: auto;
  margin: 0;
  padding: 20% 10px 10px 10px;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(var(--secondary-rgb), 0.8) 0%,
    rgba(var(--secondary-rgb), 0.8) 50%,
    rgba(var(--secondary-rgb), 0) 100%
  );
}

.grid .gridItem:first-child {
  grid-area: 1 / 1 / 3 / 11;
}
.grid1 .gridItem:nth-child(2) {
  grid-area: 1 / 12 / 3 / 15;
}
.grid1 .gridItem:nth-child(3) {
  grid-area: 4 / 1 / 7 / 6;
}
.grid1 .gridItem:nth-child(4) {
  grid-area: 3 / 6 / 6 / 10;
}
.grid1 .gridItem:nth-child(5) {
  grid-area: 3 / 10 / 7 / 15;
}
.grid1 .gridItem:nth-child(6) {
  grid-area: 4 / 15 / 7 / 18;
}
.grid1 .gridItem:nth-child(7) {
  grid-area: 7 / 3 / 9 / 6;
}
.grid1 .gridItem:nth-child(8) {
  grid-area: 6 / 6 / 9 / 10;
}
.grid1 .gridItem:nth-child(9) {
  grid-area: 7 / 10 / 10 / 14;
}
.grid1 .gridItem:nth-child(10) {
  grid-area: 7 / 14 / 9 / 17;
}
.grid1 .gridItem:nth-child(11) {
  grid-area: 6 / 15 / 8 / 18;
}

// grid 2

.grid2 .gridItem:nth-child(2) {
  grid-area: 1 / 2 / 3 / 6;
}
.grid2 .gridItem:nth-child(3) {
  grid-area: 3 / 4 / 7 / 8;
}
.grid2 .gridItem:nth-child(4) {
  grid-area: 3 / 8 / 7 / 13;
}
.grid2 .gridItem:nth-child(5) {
  grid-area: 4 / 13 / 8 / 16;
}
.grid2 .gridItem:nth-child(6) {
  grid-area: 5 / 16 / 8 / 18;
}
.grid2 .gridItem:nth-child(7) {
  grid-area: 3 / 1 / 8 / 4;
}
.grid2 .gridItem:nth-child(8) {
  grid-area: 7 / 4 / 10 / 9;
}
.grid2 .gridItem:nth-child(9) {
  grid-area: 7 / 9 / 11 / 13;
}
.grid2 .gridItem:nth-child(10) {
  grid-area: 8 / 13 / 10 / 18;
}
.grid2 .gridItem:nth-child(11) {
  grid-area: 5 / 16 / 8 / 18;
}
