.notFound {
  flex-grow: 1;
  background: url('./map.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
