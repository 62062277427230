.popoverBody {
  color: var(--secondary);
}
.popover h3 {
  color: var(--primary);
  background: white;
}

.date {
  color: var(--secondary) !important;
  font-family: var(--font-family-sans-serif);
  font-size: 0.75rem;
}

.dot {
  stroke: none;
  fill: white;
  transition: fill var(--transition);
}

.dot:hover {
  stroke: var(--primary);
  fill: var(--primary) !important;
}
