.dropdownMenu {
  min-width: unset;
  border-radius: 0;
}

.dropdownToggle {
  //padding: 0.25rem 1.5rem;
}

.dropdownMenu button,
.dropdownToggle {
  text-transform: uppercase;
}
