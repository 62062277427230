.perspectiveDetailCont {
  height: 100%;
  overflow-y: auto;
}
.title {
  font-size: 1.875rem;
}

.description {
  font-size: 1rem;
}

.chapter {
  margin-bottom: 2px;
  background-color: var(--gray);
}

.timelineContainer {
  background: #2f322f;
  margin: 10px 0;
}

@media (min-width: 576px) {
  .chapters {
    padding-left: calc(50vw - (var(--max-width-sm) / 2));
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 1.5rem;
  }

  .chaptersCont {
    height: calc(100% - 55px);
    position: sticky;
    top: 55px;
  }

  .chapters {
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: calc(50vw - (var(--max-width-md) / 2));
  }

  .chapter {
    overflow-y: auto;
    flex: 0 0 45%;
    max-width: 600px;
    border-right: 2px solid var(--secondary);
    border-radius: 10px;
    background-color: var(--gray);
  }

  .chapter:first-of-type {
    border-left: 2px solid var(--secondary);
  }
}

@media (min-width: 992px) {
  .chaptersCont {
    height: 100%;
    position: relative;
  }

  .chapter {
    flex: 0 0 40%;
  }

  .chapters {
    padding-left: calc(50vw - (var(--max-width-lg) / 2));
  }
}

@media (min-width: 1200px) {
  .chapters {
    padding-left: calc(50vw - (var(--max-width-xl) / 2));
  }
}

@media (min-width: 1400px) {
  .chapters {
    padding-left: calc(50vw - (var(--max-width-xxl) / 2));
  }
}
