.InfoPdfContainer {
  height: 80%;
}
.PdfContainer {
  padding: 65px 1.5rem 1.5rem 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.PdfDocumentContainer {
  flex-grow: 1;
  min-height: 0;
  display: flex;
  max-width: 100%;
}

.pdfDocument {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.pdfPage {
  height: 100%;
  overflow: auto;
}

.pdfPage span {
  pointer-events: none !important;
}

.PdfControls {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  input {
    background: #d8d8d8;
    border: none;
    border-radius: 5px;
    width: 50px;
    text-align: center;
  }
}
