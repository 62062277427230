.VideoContainer {
  height: 80%;
  padding: 55px 1.5rem 1.5rem 1.5rem;
}

.PlayerContainer {
  height: 100%;
  display: flex;
  justify-content: center;
}

.PlayerWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
