.playerWrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.detail {
  padding-top: unset !important;
  width: 100%;
  height: 100%;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}
