.ZoomAndPanMedia {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 65px 1.5rem 1.5rem 1.5rem;
  overflow: hidden;
  .ZoomAndPanMediaContainer {
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .ZoomAndPanMediaContainer .Zoomable {
    cursor: grab;
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
    vertical-align: middle;
    user-select: none;
  }
  .ZoomAndPanMediaContainer .Zoomable:active {
    cursor: grabbing;
  }
  .ZoomAndPanMediaControls {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
}
