.PlayerPage {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.PlayerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.Player {
  position: relative;
  height: 45% !important;
  width: auto !important;
  overflow: hidden;
  video {
    object-fit: cover;
  }
  flex-grow: 1;
  flex-shrink: 0;
}

.playControl {
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(45% / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.Controls {
  background: none;
  flex-grow: 0;
  overflow-y: auto;
}

.Controls:hover #playerControl {
  opacity: 1;
}

#playerControl {
  opacity: 0;
  transition: opacity var(--transition);
}

.controlsWrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--secondary);
}

.subtitles p {
  text-align: center;
  font-family: var(--font-family-sans-serif);
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .Player {
    height: 100% !important;
  }

  .playControl {
    top: 50%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .Player {
    height: 45% !important;
  }

  .playControl {
    top: calc(45% / 2);
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .PlayerWrapper {
    justify-content: flex-end;
  }

  .Player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 100% !important;
    overflow: hidden;
  }

  .playControl {
    width: 100px;
    height: 100px;
    top: 50%;
  }

  .Controls {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .controlsWrapper {
    position: relative;
    background-color: unset;
  }

  .subtitles{
    position: absolute;
    left: 50%;
    margin-left: -35%;
    width: 70%;
  }

  .subtitles p {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px 16px;
    border-radius: 8px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
