.explorationPage {
  position: relative;
}

.stacks {
  overflow-y: auto;
  overflow-x: hidden;
}

.desc {
  font-size: 1rem;
}

@media (min-width: 576px) {
  .stacks {
    padding-left: calc(50vw - (var(--max-width-sm) / 2));
  }
}

@media (min-width: 768px) {
  .explorationPage {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .desc {
    font-size: 1.5rem;
  }

  .stacks {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding-left: calc(50vw - (var(--max-width-md) / 2));
  }
}

@media (min-width: 992px) {
  .stacks {
    padding-left: calc(50vw - (var(--max-width-lg) / 2));
  }
}

@media (min-width: 1200px) {
  .stacks {
    padding-left: calc(50vw - (var(--max-width-xl) / 2));
  }
}

@media (min-width: 1400px) {
  .stacks {
    padding-left: calc(50vw - (var(--max-width-xxl) / 2));
  }
}
