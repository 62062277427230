.categoryPage {
  height: 100%;
  overflow-y: auto;
}
.catCont {
  position: sticky;
  top: 55px;
  z-index: 1;
}
.rowPadding {
  padding-left: 15px;
}

.rowContainer {
  will-change: opacity;
  opacity: 1 !important;
  border-bottom: 1px solid var(--border-color);
}

.rowContainer:first-of-type {
  border-top: 1px solid var(--border-color);
}

.title {
  font-size: 1.875rem;
}

.description {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.label::before {
  content: '─ ';
}

.preview {
  max-height: 200px;
  max-width: 200px;
  filter: brightness(0.75);
  transition: filter var(--transition);
}

.preview:hover {
  filter: brightness(1);
}

.notFound {
  height: 200px;
  width: 200px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
}

.previewContainer {
  overflow: visible;
  position: relative;
}

.previewContainer:hover {
  z-index: 1;
}

@media (min-width: 576px) {
  .rowPadding {
    padding-left: calc(50vw - (var(--max-width-sm) / 2));
  }
}

@media (min-width: 768px) {
  .rowPadding {
    padding-left: calc(50vw - (var(--max-width-md) / 2));
  }
}

@media (min-width: 992px) {
  .catCont {
    top: 0;
  }

  .description {
    font-size: 1.5rem;
  }

  .title {
    font-size: 3rem;
  }

  .rowContainer {
    opacity: unset;
  }

  .rowPadding {
    padding-left: calc(50vw - (var(--max-width-lg) / 2));
  }
}

@media (min-width: 1200px) {
  .rowPadding {
    padding-left: calc(50vw - (var(--max-width-xl) / 2));
  }
}

@media (min-width: 1400px) {
  .rowPadding {
    padding-left: calc(50vw - (var(--max-width-xxl) / 2));
  }
}
