.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0);
  transition: background var(--transition);
}

.bgWrapper {
  background: rgba(0, 0, 0, 0.5);
}

h1 {
  font-size: 2.25rem;
}

h4 {
  font-size: 1.5rem;
  margin: 20px 0 20px 50px;
}

#startLink:hover {
  text-decoration: none;
}

#startLink:hover #start {
  background-color: white;
  color: var(--primary);
}

#start {
  width: 135px;
  height: 135px;
  border-radius: 100%;
  border: 1px solid white;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  transition: background-color var(--transition), color var(--transition);
}

#start p {
  max-width: calc(135px / 2);
}

.mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 1;
  background-color: var(--secondary);
  transition: opacity 2s ease-in-out;
}

.mapContainer.hide {
  opacity: 0;
}

.Player {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  video {
    object-fit: cover;
  }
}

.subtitles p {
  text-align: center;
  font-family: var(--font-family-sans-serif);
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px 16px;
  border-radius: 8px;
}

.playerControls {
  position: relative;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  display: flex;
}

.playerControls.show {
  opacity: 1;
}

.playControl {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.skip {
  opacity: 0;
  transition: opacity var(--transition);
  transition-delay: 1s;
}

.skip.showSkip {
  opacity: 1;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  h1 {
    font-size: 4.5rem;
  }

  h4 {
    font-size: 2.25rem;
    margin: 20px 0 20px 100px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .playerControls {
    position: absolute;
  }

  .playControl {
    width: 100px;
    height: 100px;
  }

  .subtitles p {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
