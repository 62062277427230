.PlayingDocument {
  position: absolute;
  height: 80px;
  top: 16px;
  right: 16px;
  transition: height var(--transition), width var(--transition);
  border: 2px solid white;
  width: 80px;
  object-fit: cover;
}

.PlayingEntity {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 5px 15px;
  background: rgba(var(--secondary-rgb), 0.9);
  max-width: 35%;

  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.75rem;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .PlayingDocument {
    top: 20px;
    right: 20px;
    height: 40%;
    width: auto;
    object-fit: unset;
    border: 4px solid white;
  }

  .PlayingDocument:hover {
    height: 60%;
  }

  .PlayingEntity {
    top: 20px;
    right: 20px;
    max-width: 35%;
    padding: 5px 15px;
    h3 {
      font-size: 1.75rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
