.ExplorationsAllPage {
  min-height: 100%;
}

.selectType,
.selectType:focus,
.selectType:active {
  background-color: var(--secondary);
  color: white;
  border: none;
  box-shadow: none;
  font-size: 1rem;
}

.selectType {
  -moz-appearance: none;
  appearence: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}
.rowSearch {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 55px;
  z-index: 1;
  background: var(--secondary);
}

.colSearch {
  border-bottom: 1px solid var(--border-color);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .selectType {
    font-size: 1.5rem;
  }

  .rowSearch {
    border-top: none;
    top: 0;
  }
  .colSearch {
    border-bottom: none;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
