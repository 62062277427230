.ModalContainer {
  position: fixed;
  background-color: rgba(var(--secondary-rgb), 0.95);
  z-index: var(--modal-z-index);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .close {
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    color: white;
    z-index: 1;
  }
}

.ImageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .ImageWrapper {
    flex: 1;
    overflow: hidden;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 15px 15px 15px;
    img {
      border: 2px solid white;
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 100%;
    }
  }
}

.InfoLabel {
  color: var(--primary);
  text-transform: capitalize;
}
.InfoLabel::before {
  content: '─ ';
}

.colOverflow {
  max-height: 100%;
  overflow-y: auto;
}

.colImg {
  height: 100%;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .ModalContainer {
    .close {
      left: 20px;
      top: 20px;
    }
  }

  .ImageContainer {
    .ImageWrapper {
      align-items: flex-end;
      padding: 75px 0px;
      img {
        border: 4px solid white;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
