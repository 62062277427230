.banner {
  bottom: 50px !important;
  left: 15px !important;
  width: calc(100% - 30px) !important;
  position: fixed !important;
  height: auto !important;
  text-align: left !important;
  background-color: var(--secondary) !important;
  display: flex;
  flex-direction: column;
  padding: 15px !important;
  border: 1px solid white;
  span {
    color: white !important;
    display: block;
    line-height: 1.2 !important;
    margin-bottom: 10px;
  }

  button {
    position: relative !important;
    margin: 0 auto 0 auto !important;
    top: unset !important;
    right: unset !important;
    background: white !important;
    width: 50%;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .banner {
    width: 30% !important;
    bottom: 15px !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
