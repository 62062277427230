.ThemeListContainer {
  flex-grow: 1;
  overflow-y: scroll;
  display: block;
}

.ThemeListItem {
  margin-top: 15px;
  margin-bottom: 15px;

  .infoCont {
    background-size: cover;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .infoCont:after {
    content: '';
    background-color: var(--secondary);
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  h2 {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a,
  a:hover {
    color: white;
    text-decoration: none;
  }

  a:hover img {
    filter: saturate(100%);
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .ThemeListContainer {
    display: grid;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
