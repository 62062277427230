.documentDetailCont {
  overflow-y: scroll;
  overflow-x: hidden;
}

.close {
  z-index: var(--modal-close-z-index);
  position: fixed;
  color: white;
  left: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  &:hover,
  &:active,
  &:visited {
    color: white;
    text-decoration: none;
  }
}
